import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import VENDORAPI from "src/API_Routes/VendorAPI/VendorAPI";
import { patchData, postData } from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddVendors = ({ HandleAddVendor, action }) => {

    const [firstName, setFirstName] = useState();
    const [CompanyName, setCompanyName] = useState();
    const [gstNo, setGstNo] = useState();
    const [doorNo, setDoorNo] = useState();
    const [streetName, setStreetName] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [pinCode, setPinCode] = useState();
    const [mobilenumber, setMobileNumber] = useState();
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [errorGst, setErrorGst] = useState(false);
    const [helperTextGst, setHelperTextGst] = useState("");
    const accessToken = sessionStorage.getItem("Token");
    const [vendorDetails, SetVendorDetails] = useState();
    const [errorPincode, setErrorPincode] = useState(false);
    const [helperTextPincode, setHelperTextPincode] = useState("");

    const specificVendorData = useSelector(
        (state) => state?.ALLVENDORLIST?.specificVendorList
    );

    console.log(specificVendorData, "hhhhhhhh");


    const validatePhoneNumber = (number) => {
        if (number === "") {
            return true; // No error if the input is empty
        }
        const phoneNumberPattern = /^[0-9]{10}$/;
        return phoneNumberPattern.test(number);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setMobileNumber(value);
            if (validatePhoneNumber(value)) {
                setError(false);
                setHelperText("");
            } else {
                setError(true);
                setHelperText("Please enter a valid 10-digit mobile number");
            }
        }
    };

    const validateGstNumber = (gstNumber) => {
        const gstRegex =
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}$/;
        return gstRegex.test(gstNumber);
    };

    const handleGstNumberChange = (e) => {
        const value = e.target.value.toUpperCase(); // Convert to uppercase
        if (value.length <= 15) {
            setGstNo(value);
            if (validateGstNumber(value)) {
                setErrorGst(false);
                setHelperTextGst("");
            } else {
                setErrorGst(true);
                setHelperTextGst("Please enter a valid 15-character GST number");
            }
        }
    };

    //Pincode Validation
    const validatePinCode = (pinCode) => {
        const pinCodeRegex = /^[0-9]{6}$/;
        return pinCodeRegex.test(pinCode);
    };

    const handlePinCodeChange = (e) => {
        const value = e.target.value;
        setPinCode(value);
        if (validatePinCode(value) || value === "") {
            setErrorPincode(false);
            setHelperTextPincode("");
        } else {
            setErrorPincode(true);
            setHelperTextPincode("Pin Code must be exactly 6 digits");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!firstName || firstName.trim() === "") {
            toast.error("Vendor Name is required");
            return;
        }
        if (!CompanyName || CompanyName.trim() === "") {
            toast.error("Company Name is required");
            return;
        }
        if (!doorNo || doorNo.trim() === "") {
            toast.error("Dor No is required");
            return;
        }
        if (!city || city.trim() === "") {
            toast.error("City is required");
            return;
        }
        if (!state || state.trim() === "") {
            toast.error("State is required");
            return;
        }
        
        if (!pinCode) {
            toast.error("Pin Code is required");
            return;
        } else if (!validatePinCode(pinCode)){
            toast.error("Pin Code must be exactly 6 digits");
            return;
        }
        if (!mobilenumber) {
            toast.error("Mobile Number is required");
            return;
        } else if (!validatePhoneNumber(mobilenumber)) {
            toast.error("Please Enter A Valid 10-digit Mobile Number");
            return;
        }
        if (!gstNo) {
            toast.error("GST NO is required");
            return;
        } else if (!validateGstNumber(gstNo)) {
            toast.error("Please Enter A Valid GST Number");
            return;
        }



        const params = {
            vendorName: firstName.trim(),
            companyName: CompanyName,
            mobileNumber: mobilenumber,
            gstNo: gstNo,
            doorNo: doorNo,
            city: city,
            state: state,
            pincode: pinCode,
            streetName: streetName,
        };

        if (action === "Edit") {
            patchData(`vendor/vendor/${specificVendorData._id}`, params, accessToken)
                .then((res) => {
                    SetVendorDetails(res.data);
                    HandleAddVendor();
                    console.log(vendorDetails);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            postData(VENDORAPI.ADDVENDOR, params, accessToken)
                .then((res) => {
                    SetVendorDetails(res.data);
                    HandleAddVendor();
                    console.log(vendorDetails);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    useEffect(() => {
        if (action === "Edit" || action === "View") {
            if (specificVendorData) {
                setFirstName(specificVendorData?.vendorName);
                setCompanyName(specificVendorData?.companyName);
                setGstNo(specificVendorData?.gstNo);
                setDoorNo(specificVendorData?.doorNo);
                setMobileNumber(specificVendorData?.mobileNumber);
                setCity(specificVendorData?.city);
                setState(specificVendorData?.state);
                setPinCode(specificVendorData?.pincode);
                setStreetName(specificVendorData?.streetName)
            }
        } else {
            setFirstName("");
            setCompanyName("");
            setGstNo("");
            setDoorNo("");
            setCity("");
            setState("");
            setPinCode("");
            setMobileNumber("");
            setStreetName("")
        }
    }, [action, specificVendorData]);


    return (
        <>
            <div className="p-6 card">
                <div className="component-title">
                    <h5>{action} Vendor</h5>
                </div>
                <div className="mb-5">
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={firstName}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setFirstName(e.target.value)}
                                label="Vendor Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={CompanyName}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setCompanyName(e.target.value)}
                                label="Company Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={gstNo}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={handleGstNumberChange}
                                label="GST No *"
                                variant="outlined"
                                inputProps={{ maxLength: 15 }}
                                error={errorGst}
                                helperText={helperTextGst}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={doorNo}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setDoorNo(e.target.value)}
                                label="Door No *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={streetName}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setStreetName(e.target.value)}
                                label="Street Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={city}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setCity(e.target.value)}
                                label="City *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={state}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setState(e.target.value)}
                                label="State *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={pinCode}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={handlePinCodeChange}
                                label="Pin Code *"
                                variant="outlined"
                                inputProps={{ maxLength: 6 }}
                                error={errorPincode}
                                helperText={helperTextPincode}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={mobilenumber}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={handlePhoneNumberChange}
                                label="Mobile No *"
                                variant="outlined"
                                inputProps={{ maxLength: 10 }}
                                error={error}
                                helperText={helperText}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className="d-flex justify-content-end mt-5">
                    {action === "Add" && (
                        <>
                            <Button
                                variant="outlined"
                                onClick={HandleAddVendor}
                                className="btn-secondary"
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                className="ml-8 btn-primary"
                            >
                                ADD VENDOR
                            </Button>
                        </>
                    )}
                    {action === "Edit" && (
                        <>
                            <Button
                                variant="outlined"
                                onClick={HandleAddVendor}
                                className="btn-secondary"
                            >
                                BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                className="ml-8 btn-primary"
                            >
                                UPDATE
                            </Button>
                        </>
                    )}
                    {action === "View" && (
                        <Button
                            variant="outlined"
                            onClick={HandleAddVendor}
                            className="btn-secondary"
                        >
                            BACK
                        </Button>
                    )}
                </div>
            </div>
        </>

    )
}

export default AddVendors;