import { createSlice } from "@reduxjs/toolkit";

const ALLVENDORLIST = createSlice({
  name: "ALLVENDORLIST",
  initialState: {
    AllVendorList: "",
    specificVendorList: ""
  },
  reducers: {
    setALLVENDORLIST: (state, action) => {
      state.AllVendorList = action.payload;
    },
    setSpecificVendorDetails: (state, action) => {
      state.specificVendorList = action.payload;
    },
  },
});

export const { setALLVENDORLIST, setSpecificVendorDetails } = ALLVENDORLIST.actions;
export default ALLVENDORLIST.reducer;
