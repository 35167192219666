import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    TableContainer,
    Paper,
} from "@mui/material";
import {
    getData,
    postData,
    deleteData,
    patchData,
    FileUpload,
} from "src/webService/webService"; // Assuming deleteData function is exported
import { toast, ToastContainer } from "react-toastify";
import Iconify from "../iconify";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import purchaseLogo from "../logo/physical-Inventory.webp";
import ProcessDataTemplate from "../templates/processDataTemplate";


function ProcessSheetTable() {
    const [machineName, setMachineName] = useState();
    const [machineNumber, setMachineNumber] = useState();
    const [shift, setShift] = useState();
    const [partName, setPartName] = useState();
    const [customerName, setCustomerName] = useState();
    const [processDataSheetFilledDate, setProcessDataSheetFilledDate] = useState();
    const [quantity, setQuantity] = useState();
    const [loadingTime, setLoadingTime] = useState();
    const [unloadingTime, setUnLoadingTime] = useState();
    const [processTime, setProcessTime] = useState();
    const [media, setMedia] = useState();
    const [airPressure, setAirPressure] = useState();
    const [noOfSamples, setNoOfSamples] = useState();
    const [okNotOk, setOkNotOk] = useState();
    const [processData, setProcessData] = useState([]);
    const [open, setOpen] = useState(false);
    const [TotalItems, setTotalItems] = useState();
    const [action, setAction] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(5);
    const [specificData, setSpecificData] = useState("");
    const [clientList, setClientList] = useState();
    const [selectedCompany, setSelectedCompany] = useState();
    const [openDialog, setOpenDialog] = useState(false);


    //For Search Filter
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [searchdescription, setsearchdescription] = useState();
    const [searchcompanyname, setsearchcompanyname] = useState();
    const [reset, setReset] = useState(false);
    const [shiftFilter, setShiftFilter] = useState();
    //For Search Filter

    const [showFilter, setShowFilter] = useState(false);

    const handleShiftFilter = (e) => {
        setShiftFilter(e.target.value);
    }

    console.log(specificData, "specificData");

    const accessToken = sessionStorage.getItem("Token");

    const allClientList = async (page) => {
        const params = {
            page: page,
            limit: null,
        }

        try {
            const res = await getData(
                'clients/clients',
                params,
                accessToken
            );
            if (res.status === 200) {
                console.log(res.data.results);
                setClientList(res.data.results);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }

    useEffect(() => {
        allClientList();
    }, [])

    const handleSubmit = (e) => {
        debugger;
        if (!machineName || machineName.trim() === "") {
            toast.error("Operator Name is required");
            return;
        }
        if (!shift || shift.trim() === "") {
            toast.error("Shift is required");
            return;
        }
        if (!partName || partName.trim() === "") {
            toast.error("Part Name is required");
            return;
        }
        if (!processDataSheetFilledDate || processDataSheetFilledDate.trim() === "") {
            toast.error("Date is required");
            return;
        }
        if (!quantity || quantity.trim() === "") {
            toast.error("Quantity is required");
            return;
        }
        if (!loadingTime || loadingTime.trim() === "") {
            toast.error("Loading Time is required");
            return;
        }
        if (!unloadingTime || unloadingTime.trim() === "") {
            toast.error("Unloading Time is required");
            return;
        }
        if (!customerName || customerName.trim() === "") {
            toast.error("Customer Name is required");
            return;
        }
        if (!media || media.trim() === "") {
            toast.error("Media is required");
            return;
        }
        if (!airPressure || airPressure.trim() === "") {
            toast.error("Air Pressure is required");
            return;
        }
        if (!noOfSamples || noOfSamples.trim() === "") {
            toast.error("No of Samples is required");
            return;
        }
        if (!okNotOk || okNotOk.trim() === "") {
            toast.error("Status is required");
            return;
        }
        if (!processTime || processTime.trim() === "") {
            toast.error("Process Time is required");
            return;
        }
        e.preventDefault();
        const params = {
            machineName,
            machineNumber,
            shift,
            customerName,
            partName,
            processDataSheetFilledDate,
            quantity,
            loadingTime,
            unloadingTime,
            media,
            noOfSamples,
            airPressure,
            okNotOk,
            processTime
        };
        if (action === "Edit") {
            patchData(`processes/process/${specificData._id}`, params, accessToken)
                .then((res) => {
                    console.log(res.data);
                    if (res.status === 200) {
                        handleClose();
                        AllpurchaseInventoryList(currentPage); // Refresh data after successful submission
                        setMachineName("");
                        setMachineNumber("");
                        setShift("");
                        setPartName("");
                        setProcessDataSheetFilledDate("");
                        setQuantity("");
                        setLoadingTime("");
                        setCustomerName("");
                        setUnLoadingTime("");
                        setMedia("");
                        setAirPressure("");
                        setNoOfSamples("");
                        setOkNotOk("");
                        setProcessTime("");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            postData('/processes/process', params, accessToken)
                .then((res) => {
                    console.log(res.data);
                    if (res.status === 200) {
                        handleClose();
                        AllpurchaseInventoryList(currentPage); // Refresh data after successful submission
                        setMachineName("");
                        setMachineNumber("");
                        setShift("");
                        setPartName("");
                        setProcessDataSheetFilledDate("");
                        setQuantity("");
                        setLoadingTime("");
                        setCustomerName("");
                        setUnLoadingTime("");
                        setMedia("");
                        setAirPressure("");
                        setNoOfSamples("");
                        setOkNotOk("");
                        setProcessTime("");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    const handleLimitChange = (event) => {
        setCurrentPage(1);
        setLimit(event.target.value);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    const handleChange = (event) => {
        debugger
        const companyName = event.target.value;
        const company = clientList?.find((c) => c._id === companyName);
        console.log(company, "company");
        setSelectedCompany(company);
        setCustomerName(company?.companyname)
    };

    const AllpurchaseInventoryList = async (page) => {

        setReset(false);
        try {
            const params = {
                page: page,
                limit: limit,
                startDate: startDate ? startDate : "",
                endDate: endDate ? endDate : "",
                machineName: searchcompanyname ? searchcompanyname : "",
                shift: shiftFilter ? shiftFilter : "",
            };
            const res = await getData(
                'processes/',
                params,
                accessToken
            );
            if (res.status === 200) {
                setTotalPages(res.data.totalPages);
                setProcessData(res.data.results);

                if (res) {
                    setTotalItems(res.data.totalItems);
                } else {
                    setTotalItems(0);
                }

            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        AllpurchaseInventoryList(currentPage);
    }, [currentPage, limit]);

    const handleDelete = async (id) => {
        const isConfirmed = await showConfirmationDialog();
        if (isConfirmed) {
            try {
                await deleteData(`processes/process/${id}`, accessToken);
                AllpurchaseInventoryList(currentPage);
            } catch (error) {
                console.error("Error:", error);
                toast.error("Failed to delete purchase");
            }
        }
    };

    const handleClickOpen = (row, action) => {
        debugger;
        setOpen(true);
        setSpecificData(row);
        setAction(action);
        setSelectedCompany(clientList.find((vendor) => vendor.companyname === row.customerName));
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePartNameChange = (event) => {
        setPartName(event.target.value);
    };

    const handleOkNotOk = (event) => {
        setOkNotOk(event.target.value);
    }
    const calculateProcessTime = (loading, unloading) => {
        if (!loading || !unloading) return;

        // Parse the time strings into hours and minutes
        const [loadingHour, loadingMinute] = loading.split(":").map(Number);
        const [unloadingHour, unloadingMinute] = unloading.split(":").map(Number);

        // Create date objects assuming the same day
        const loadingDate = new Date(0, 0, 0, loadingHour, loadingMinute);
        const unloadingDate = new Date(0, 0, 0, unloadingHour, unloadingMinute);

        // Calculate the difference in milliseconds
        let diff = unloadingDate - loadingDate;

        // Handle the case where unloading time is earlier (e.g., after midnight)
        if (diff < 0) {
            diff += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
        }

        const diffMinutes = Math.floor(diff / (1000 * 60)); // Convert to minutes

        // Calculate hours and remaining minutes
        const hours = Math.floor(diffMinutes / 60);
        const minutes = diffMinutes % 60;

        // Format the output
        let formattedTime = "";
        if (hours > 0) {
            formattedTime += `${hours}hr${hours > 1 ? "s" : ""} `;
        }
        if (minutes > 0) {
            formattedTime += `${minutes}min${minutes > 1 ? "s" : ""}`;
        }

        setProcessTime(formattedTime.trim());
    };
    // index based on currentPage
    const calculateIndex = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const handleSearch = () => {
        setCurrentPage(1);
        AllpurchaseInventoryList(1);
    };


    useEffect(() => {
        if (reset) {
            setCurrentPage(1);
            AllpurchaseInventoryList(1);
        }
    }, [reset]);

    useEffect(() => {
        console.log("specificData:", specificData);
        console.log("selectedCompany:", selectedCompany);
    }, [specificData, selectedCompany]);

    useEffect(() => {
        debugger
        if (action === "Edit") {
            if (specificData) {
                setMachineName(specificData?.machineName);
                setMachineNumber(specificData?.machineNumber);
                setShift(specificData?.shift);
                setPartName(specificData?.partName);
                setCustomerName(specificData?.customerName);
                setProcessDataSheetFilledDate(specificData?.processDataSheetFilledDate);
                setQuantity(specificData?.quantity);
                setLoadingTime(specificData?.loadingTime);
                setUnLoadingTime(specificData?.unloadingTime);
                setProcessTime(specificData?.processTime)
                setMedia(specificData?.media);
                setAirPressure(specificData?.airPressure);
                setNoOfSamples(specificData?.noOfSamples);
                setOkNotOk(specificData?.okNotOk);
            }
        } else {
            setMachineName("");
            setMachineNumber("");
            setShift("");
            setPartName("");
            setProcessDataSheetFilledDate("");
            setQuantity("");
            setLoadingTime("");
            setCustomerName("");
            setUnLoadingTime("");
            setMedia("");
            setAirPressure("");
            setNoOfSamples("");
            setOkNotOk("");
            setProcessTime("");
        }
    }, [action, specificData]);

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const removeData = () => {
        setStartDate();
        setEndDate();
        setsearchcompanyname();
        setShiftFilter();
        setReset(true);
    };

    const handleopen = () => {
        setOpenDialog(true);
        setProcessData(processData);
    };

    const handleCloses = () => {
        setOpenDialog(false);
    };

    const reportTemplateRef = useRef(null);

    const handlePrint = () => {
        const printContent = reportTemplateRef.current;
        const originalContent = document.body.innerHTML;
        // Set the document's body to only include the printContent
        document.body.innerHTML = printContent.innerHTML;
        // Trigger the print dialog
        window.print();
        // Restore the original document content
        document.body.innerHTML = originalContent;
        // Reattach the React component to the DOM
        window.location.reload();
    };


    return (
        <Paper className="card-body">
            <Dialog open={openDialog} onClose={handleCloses} maxWidth="lg" fullWidth>
                <DialogTitle>Invoice Report</DialogTitle>
                <DialogContent>
                    <div ref={reportTemplateRef} style={{ width: "67.5%" }}>
                        {
                            processData &&
                            <ProcessDataTemplate data={processData} shift={shiftFilter} operatorName={searchcompanyname} startDate={startDate} />
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        size="small"
                        className="ml-8 btn-secondary"
                        onClick={handleCloses}
                    >
                        Close
                    </Button>
                    <Button
                        size="small"
                        className="ml-8 btn-primary"
                        onClick={() => {
                            handlePrint();
                        }}
                        variant="contained"
                    >
                        Print PDF
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer position="top-right" />
            <div className="d-flex align-items-center justify-content-center pt-3 pb-4">
                <div className="d-flex col-6">
                    <img src={purchaseLogo} width="7%" className="mr-2" />
                    <p className="fw-bold mb-0 me-4 mt-2 color-dark">
                        Total Process Data : {TotalItems}
                    </p>

                </div>

                <div className="d-flex align-items-center col-6 justify-content-end">

                    {showFilter && (
                        <span
                            className="me-2 danger"
                            style={{ color: "red", fontSize: "13px", cursor: "pointer" }}
                            onClick={(e) => {
                                toggleFilter();
                                removeData();
                            }}
                        >
                            Clear Filter
                        </span>
                    )}
                    <IconButton
                        className="p-12 ftrbkgd rounded"
                        onClick={(e) => {
                            toggleFilter();
                            removeData();
                        }}
                    >
                        {showFilter ? (
                            <Iconify icon="ri:filter-off-fill" className="color-dark" />
                        ) : (
                            <Iconify
                                icon="material-symbols:filter-alt-sharp"
                                className="color-dark"
                            />
                        )}
                    </IconButton>

                    <Button
                        variant="contained"
                        size="small"
                        className="ml-8 btn-primary maxWidth"
                        onClick={() => handleClickOpen("", "Add")}
                    >
                        + ADD PROCESS DATA
                    </Button>

                    <Button
                        variant="outlined"
                        size="small"
                        className="ml-8 btn-secondary maxWidth"
                        onClick={handleopen}
                        disabled={TotalItems === 0}
                    >
                        EXPORT PDF
                    </Button>
                </div>
            </div>
            <div>
                {showFilter && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                marginTop: "20px",
                                marginBottom: "20px",
                            }}
                        >
                            <div style={{ minWidth: 500, margin: 1 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    className="d-flex flex-warp justify-content-between align-items-center"
                                >
                                    <TextField
                                        type="date"
                                        className="mx-1"
                                        label="Start Date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        type="date"
                                        className="mx-1"
                                        label="End Date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: startDate,
                                        }}
                                    />
                                    <TextField
                                        className="my-0 mx-1"
                                        label="Search Operator Name"
                                        value={searchcompanyname}
                                        onChange={(e) => setsearchcompanyname(e.target.value)}
                                        variant="outlined"
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            maxLength: 20,
                                        }}
                                    />
                                    <FormControl
                                        sx={{ m: 1, minWidth: 178 }}
                                        size="large"
                                        className="m-0 select"
                                    >
                                        <InputLabel id="demo-select-small-label">Shift</InputLabel>
                                        <Select
                                            labelId="demo-select-large-label"
                                            id="demo-select-large"
                                            value={shiftFilter}
                                            InputProps={{
                                                readOnly: action === "View",
                                            }}
                                            label="Shift"
                                            onChange={handleShiftFilter}
                                        >
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="outlined"
                                        className="mx-1 btn-secondary"
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
                <DialogTitle>{action} Process Data Sheet</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100 mt-2"
                                id="outlined-basic"
                                value={machineName}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setMachineName(e.target.value)}
                                label="Operator Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextField
                                className="w-100 mt-2"
                                id="outlined-basic"
                                value={machineNumber}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setMachineNumber(e.target.value)}
                                label="Machine Number *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid> */}
                        <Grid item xs={4}>
                            <TextField
                                className="w-100 mt-2"
                                id="outlined-basic"
                                value={shift}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setShift(e.target.value)}
                                label="Shift *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                sx={{ m: 1, Width: 100 }}
                                size="large"
                                className="m-0 w-100 select mt-2"
                            >
                                <InputLabel id="demo-select-small-label">Company Name</InputLabel>
                                <Select
                                    labelId="demo-select-large-label"
                                    id="demo-select-large"
                                    value={
                                        selectedCompany?._id ||
                                        selectedCompany
                                    }
                                    InputProps={{
                                        readOnly: action === "View",
                                    }}
                                    label="Company Name"
                                    onChange={handleChange}
                                >
                                    {clientList &&
                                        clientList.map((company) => (
                                            <MenuItem key={company._id} value={company._id}>
                                                {company.companyname}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                sx={{ m: 1, Width: 100 }}
                                size="large"
                                className="m-0 w-100 select"
                            >
                                <InputLabel id="demo-select-small-label">Part Name</InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    name="partName"
                                    label="Part Name"
                                    className="w-100"
                                    value={partName}
                                    onChange={handlePartNameChange}
                                    disabled={action === "View"}
                                >
                                    {selectedCompany?.product?.map((product, i) => (
                                        <MenuItem key={i} value={product.partName}>
                                            {product.partName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                type="date"
                                value={processDataSheetFilledDate}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setProcessDataSheetFilledDate(e.target.value)}
                                label="Date *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={quantity}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setQuantity(e.target.value)}
                                label="Quantity *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="loading-time"
                                type="time"
                                value={loadingTime}
                                onChange={(e) => {
                                    setLoadingTime(e.target.value);
                                    calculateProcessTime(e.target.value, unloadingTime);
                                }}
                                label="Loading Time *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="unloading-time"
                                type="time"
                                value={unloadingTime}
                                onChange={(e) => {
                                    setUnLoadingTime(e.target.value);
                                    calculateProcessTime(loadingTime, e.target.value);
                                }}
                                label="Unloading Time *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={processTime}
                                InputProps={{
                                    readOnly: true,
                                }}
                                label="Process Time *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={media}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setMedia(e.target.value)}
                                label="Media *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={airPressure}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setAirPressure(e.target.value)}
                                label="Air Pressure *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                className="w-100"
                                id="outlined-basic"
                                value={noOfSamples}
                                InputProps={{
                                    readOnly: action === "View",
                                }}
                                onChange={(e) => setNoOfSamples(e.target.value)}
                                label="No Of Samples *"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                sx={{ m: 1, Width: 100 }}
                                size="large"
                                className="m-0 w-100 select"
                            >
                                <InputLabel id="demo-select-small-label">Status</InputLabel>
                                <Select
                                    fullWidth
                                    variant="outlined"
                                    name="OkNotOk"
                                    label="Status"
                                    className="w-100"
                                    value={okNotOk}
                                    onChange={handleOkNotOk}
                                    disabled={action === "View"}
                                >
                                    <MenuItem value="Ok">Ok</MenuItem>
                                    <MenuItem value="Not Ok">Not Ok</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                    </Grid>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        className="mt-3"
                    >
                        {/* <Button
                            variant="outlined"
                            className="btn-secondary"
                            onClick={handleClose}
                        >
                            CLOSE
                        </Button>
                        <Button
                            style={{ width: "133px" }}
                            type="submit"
                            variant="contained"
                            className="ml-8 btn-primary"
                            onClick={handleSubmit}
                        >
                            ADD
                        </Button> */}
                        {action === "Add" && (
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                    className="btn-secondary"
                                >
                                    CANCEL
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    className="ml-8 btn-primary"
                                >
                                    ADD PROCESS DATA
                                </Button>
                            </>
                        )}
                        {action === "Edit" && (
                            <>
                                <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                    className="btn-secondary"
                                >
                                    BACK
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    className="ml-8 btn-primary"
                                >
                                    UPDATE
                                </Button>
                            </>
                        )}
                        {action === "View" && (
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                className="btn-secondary"
                            >
                                BACK
                            </Button>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
            <TableContainer style={{ height: "320px", overflowY: "scroll" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cl color-dark text-center">
                                S.No
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Operator Name
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Date
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Loading Time
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Unloading Time
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Company Name
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                Shift
                            </TableCell>
                            <TableCell className="table-cl color-dark">
                                status
                            </TableCell>
                            <TableCell className="table-cl color-dark">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {processData?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell className="text-center">
                                    {calculateIndex(index)}
                                </TableCell>
                                <TableCell>{row?.machineName}</TableCell>
                                <TableCell>{row?.processDataSheetFilledDate?.split("T")[0]
                                    ?.split("-")
                                    ?.reverse()
                                    ?.join("-")}</TableCell>
                                <TableCell>{row?.loadingTime}</TableCell>
                                <TableCell>{row?.unloadingTime}</TableCell>
                                <TableCell>
                                    {row?.customerName}
                                </TableCell>
                                <TableCell>
                                    {row?.shift}
                                </TableCell>
                                <TableCell>
                                    {row?.okNotOk ===
                                        "Ok" ? (
                                        <span class="badge text-bg-success f-13">
                                            OK
                                        </span>
                                    ) : (
                                        <span class="badge text-bg-danger f-13">
                                            Not Ok
                                        </span>
                                    )}
                                </TableCell>

                                <TableCell>
                                    <Iconify
                                        icon={"eva:edit-fill"}
                                        sx={{ mr: 2 }}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleClickOpen(row, "Edit")}
                                    />
                                    <Iconify
                                        icon={"eva:trash-2-outline"}
                                        sx={{ mr: 2 }}
                                        style={{ cursor: "pointer", color: "red" }}
                                        onClick={() => handleDelete(row._id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}

                        {processData?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={9} className="text-center">
                                    No Records Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
                    <Select
                        labelId="limit-label"
                        id="limit-select"
                        value={limit}
                        onChange={handleLimitChange}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={TotalItems}>All</MenuItem>
                    </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <Iconify icon={"grommet-icons:next"} />
                </Button>
            </div>
        </Paper>
    );
}

export default ProcessSheetTable;
