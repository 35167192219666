import React from "react";

const ProcessDataTemplate = ({ data, shift, operatorName, startDate }) => {
    debugger;
    return (
        <div>
            <style>
                {`
                table, th, td {
                    border: 0.3px solid #272727;
                    border-collapse: collapse;
                    padding: 6px !important;
                    font-size: 8px !important;
                }
                th{
                    text-align: center
                }
                .f-14{
                    font-size: 14px !important;
                }
                .bt {
                    border-top: none !important;
                }
                .bb {
                    border-bottom: none !important;
                }
                .bl {
                    border-left: none !important;     
                }
                .br {
                    border-right: none !important;
                }
                .bold{
                    font-weight: 700;
                }
                .w-20{
                    width: 20%;
                }
                .w-10{
                    width: 10%;
                }
                `}
            </style>
            <div style={{ width: "100%" }}>
                <h1 style={{ fontSize: '16px', textAlign: 'center' }}>PROCESS DATA SHEET</h1>
                <table style={{ width: "99%" }}>
                    <tr>
                        <th colspan="3" className="br">USF SURFACE FINISHERS</th>
                        <th colspan="3" className="bl br">PROCESS DATA SHEET</th>
                        <th colspan="2" className="bl br">SHIFT: {shift ? shift : "NULL"}</th>
                        <th colspan="4" className="bl">OPERATOR NAME: {operatorName ? operatorName : "NULL"} / DATE: {startDate ? startDate : "NULL"}</th>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <th>Partname</th>
                        <th>Customer</th>
                        <th colspan="2">Quantity</th>
                        <th>Loading Time</th>
                        <th>Unloading Time</th>
                        <th>Process Time</th>
                        <th>Media</th>
                        <th>Air pressure</th>
                        <th colspan="2">In Process inspection</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>No's</th>
                        <th>Kgs</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>No of samples</th>
                        <th>OK / Not OK</th>

                    </tr>
                    {data.map((data, index) => (
                        <tr key={index}>
                            <td>{data?.processDataSheetFilledDate.split("T")[0]
                                    ?.split("-")
                                    ?.reverse()
                                    ?.join("-")}</td>
                            <td>{data?.partName}</td>
                            <td>{data?.customerName}</td>
                            <td>{data?.quantity}</td>
                            <td></td>
                            <td>{data?.loadingTime}</td>
                            <td>{data?.unloadingTime}</td>
                            <td>{data?.processTime}</td>
                            <td>{data?.media}</td>
                            <td>{data?.airPressure}</td>
                            <td>{data?.noOfSamples}</td>
                            <td>{data?.okNotOk}</td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default ProcessDataTemplate;