import React, { useEffect, useState } from "react";
import "../AllClients/AllClients.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Popover,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  DialogContent,
  InputAdornment,
} from "@mui/material";
import Iconify from "../iconify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import AddClient from "./AddClient";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import CLIENTAPI from "src/API_Routes/ClientAPI/ClientAPI";
import PRODUCTAPI from "src/API_Routes/ProductAPI/product-API";
import {
  setALLCLIENTSLIST,
  setSpecificClientsDetails,
} from "src/redux/Slices/AllClientSlices";
import AddProduct from "../product/product";
import { showConfirmationDialog } from "../../utils/sweetalertUtils";
import clientLogo from "../logo/rating.png";

const AllClients = () => {
  const dispatch = useDispatch();
  const [searchCompanyName, setSearchCompanyName] = useState("");
  const [open, setOpen] = useState(null);
  const [opendialog, setOpendialog] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [action, setAction] = useState("");
  const [addClient, setAddClient] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [TotalItems, setTotalItems] = useState();
  const accessToken = sessionStorage.getItem("Token");
  const [sendMail, setSendMail] = useState();
  const [errorMail, setErrorMail] = useState(false);
  const [helperTextMail, setHelperTextMail] = useState("");
  const ALLCLIENTSLIST = useSelector(
    (state) => state?.ALLCLIENTSLIST?.AllClientsList?.results
  );
  const [selectedId, setSelectedId] = useState();
  const [addProduct, setAddProduct] = useState(false);
  const [nagesh, setNagesh] = useState(null);
  console.log(action, "action");
  const [Uploadopen, setUploadopen] = useState(false);

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
    unselectAllCheckboxes();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const AllClientsList = async (page) => {
    const params = {
      page: page,
      limit: limit,
      companyname: searchCompanyName,
    };
    try {
      const res = await getData(CLIENTAPI.GETCLIENTS, params, accessToken);
      if (res.status === 200) {
        dispatch(setALLCLIENTSLIST(res.data));
        setTotalPages(res.data.totalPages);
        if (res?.data?.totalItems) {
          setTotalItems(res.data.totalItems);
        } else {
          setTotalItems(0);
        }

        console.log(ALLCLIENTSLIST, "Allclientslist");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  const deleteClient = async (userId) => {
    const isConfirmed = await showConfirmationDialog();
    if (isConfirmed) {
      await deleteData(`clients/client/${userId}`, accessToken)
        .then((res) => {
          setOpen(null);
          AllClientsList(currentPage);
          unselectAllCheckboxes();
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
        });
    }
  };

  useEffect(() => {
    AllClientsList(currentPage);
  }, [currentPage, limit]);

  const specificBillerData = async (userId) => {
    console.log(userId, "userid");
    try {
      const res = await getData(`clients/client/${userId}`, null, accessToken);
      if (res.status === 200) {
        dispatch(setSpecificClientsDetails(res.data));
        setNagesh(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {});
    }
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    // specificBillerData();
    setSelectedId(id);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenDialog = (event) => {
    setOpendialog(true);
  };

  const handleCloseDialog = () => {
    setOpendialog(null);
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    ALLCLIENTSLIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  const HandleAddClient = () => {
    setAddClient(!addClient);
    unselectAllCheckboxes();
  };

  const handleClickOpen = () => {
    setUploadopen(true);
  };
  const handleClose = () => {
    setUploadopen(false);
  };

  const HandleAddProduct = (id) => {
    setTimeout(() => {
      setSelectedId(id);
      setAddProduct(!addProduct);
      console.log("AddProduct called");
      console.log(id, "id");
    }, 300); // 1000 milliseconds = 1 second
  };

  const handleExport = () => {
    const hasSelected = Object.values(isChecked).some((checked) => checked);

    if (!hasSelected) {
      toast.error("Select Customer Check Box");
      return;
    }
    const headers = [
      "Company Name",
      "Branch",
      "Phone Number",
      "Email ID",
      "Door No",
      "Street Name",
      "City",
      "Pin Code",
      "GST No",
    ].filter(Boolean);

    // Filter checked items
    const checkedItems = ALLCLIENTSLIST.filter((item) => isChecked[item._id]);

    let data;
    data = checkedItems.map((item) => {
      return [
        item?.companyname,
        item?.branch,
        item?.phoneNumber,
        item?.email,
        item?.doorNo,
        item?.streetName,
        item?.city,
        item?.pinCode,
        item?.gstNo,
      ];
    });
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Customers Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "Customers_data.xlsx"
    );
    unselectAllCheckboxes();
  };

  const unselectAllCheckboxes = () => {
    const updatedIsChecked = {};
    ALLCLIENTSLIST.forEach((row) => {
      updatedIsChecked[row._id] = false;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(false);
  };

  useEffect(() => {
    if (searchCompanyName.trim()) {
      unselectAllCheckboxes();
      AllClientsList(1);
    }
  }, [searchCompanyName]);

  const clearSearch = () => {
    setSearchCompanyName("");
  };

  useEffect(() => {
    if (searchCompanyName === "") {
      AllClientsList(1);
      setLimit(5);
    }
  }, [searchCompanyName]);

  const sendMailApi = async () => {
    const mailid = {
      email: sendMail
    }
    await postData('clients/sendmail', mailid, accessToken)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
      setSendMail("");
      handleCloseDialog();
  }

  //Mail Validation
  const validateEmail = (email) => {
    if (email === "") {
      return true; // No error if the input is empty
    }
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (validateEmail(value)) {
      setErrorMail(false);
      setHelperTextMail("");
    } else {
      setErrorMail(true);
      setHelperTextMail("Please enter a valid email address");
    }
    setSendMail(value);
  };

  return (
    <Paper className="card-body">
      <ToastContainer position="top-right" />

      {addProduct ? (
        <AddProduct
          HandleAddProduct={HandleAddProduct}
          nagesh={nagesh}
          setNagesh={setNagesh}
        />
      ) : (
        <>
          {addClient ? (
            <>
              <Dialog open={opendialog} onClose={handleCloseDialog} >
                <DialogTitle>Send Mail</DialogTitle>
                <DialogContent className="text-center pt-3">
                  <TextField
                    className="w-100"
                    id="outlined-basic"
                    value={sendMail}
                    onChange={handleEmailChange}
                    label="Send Mail "
                    variant="outlined"
                    error={errorMail}
                    helperText={helperTextMail}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    className="mt-3 w-100 btn-primary"
                    onClick={() => {
                      sendMailApi();
                    }}
                  >
                    <Iconify icon="streamline:send-email" className="mr-2" /> <p className="mb-0">SEND MAIL</p>
                  </Button>
                </DialogContent>
              </Dialog>
              <div className="pt-3 pb-4 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center col-3">
                  <img src={clientLogo} width="8%" className="mr-2" />
                  <p className="fw-bold mb-0 color-dark mt-1">
                    {" "}
                    Total Customers :{" "}
                  </p>
                  <p className="fw-bold mb-0 color-dark mt-1"> {TotalItems}</p>
                </div>
                <div className="d-flex align-items-end justify-content-end col-9">
                  <div className="position-relative col-4">
                    <TextField
                      style={{ borderRadius: "10px 10px 0px 0px" }}
                      label="Search Company Name"
                      variant="outlined"
                      className="mb-0 w-100"
                      value={searchCompanyName}
                      size="small"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 7) {
                          setSearchCompanyName(value);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="ic:baseline-search" />
                          </InputAdornment>
                        ),
                        endAdornment: searchCompanyName && (
                          <InputAdornment position="end">
                            <IconButton onClick={clearSearch}>
                              <Iconify icon="ic:outline-clear" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ width: "165px" }}
                    onClick={handleExport}
                    className="ml-8 btn-secondary"
                  >
                    EXPORT
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className="ml-8 btn-primary"
                    onClick={() => {
                      setOpen(null);
                      setAction("Add");
                      HandleAddClient();
                    }}
                  >
                    + ADD CUSTOMER
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    className="ml-8 btn-primary"
                    onClick={() => {
                      handleOpenDialog();
                    }}
                  >
                    SEND MAIL
                  </Button>
                </div>
              </div>
              <TableContainer style={{ height: "361px", overflowY: "scroll", width: "100%" }}>
                <Table stickyHeader style={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="table-cl mx-5"
                        style={{ padding: "8px 16px" }}
                      >
                        <Checkbox
                          className="color-white"
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-5" align="center">
                        S.No
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-10" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "10%" }}>
                        Company Name
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-20">
                        Email ID
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-15">
                        Mobile No
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-15">
                        GST No
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-10" align="center">
                        Add / View Part
                      </TableCell>
                      <TableCell className="table-cl color-dark mx-10">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ width: "100%" }}>
                    {ALLCLIENTSLIST &&
                      ALLCLIENTSLIST.map((row, index) => (
                        <TableRow key={index} className="padding-8">
                          <TableCell padding="checkbox" className="mx-5">
                            <Checkbox
                              checked={isChecked[row._id] || false}
                              onChange={(event) =>
                                handleCheckboxChange(event, row._id)
                              }
                            />
                          </TableCell>
                          <TableCell align="center" className="mx-5">{index + 1}</TableCell>
                          <TableCell
                            className="cursor hiddenEllipsis "
                            onClick={(e) => {
                              HandleAddProduct(row._id);
                              specificBillerData(row._id);
                            }}
                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "250px" }}
                          >
                            {row.companyname}
                          </TableCell>

                          <TableCell className="mx-20" style={{ maxWidth: "200px" }}>{row.email ? row.email : "-"}</TableCell>
                          <TableCell className="mx-15%">
                            {row.phoneNumber ? row.phoneNumber : "-"}
                          </TableCell>
                          <TableCell className="mx-15">{row.gstNo}</TableCell>
                          <TableCell align="center" className="mx-10">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                HandleAddProduct(row._id);
                                specificBillerData(row._id);
                              }}

                            >
                              <Iconify icon={"material-symbols:add"} />
                            </IconButton>
                          </TableCell>
                          <TableCell className="mx-10%">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) =>
                                handleOpenMenu(event, row._id)
                              }
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    {!ALLCLIENTSLIST && (
                      <TableRow>
                        <TableCell colSpan={7} className="text-center">
                          No Records Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={TotalItems}>All</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                PaperProps={{
                  sx: {
                    p: 1,
                    width: 190,
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                    },
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    specificBillerData(selectedId);
                    setOpen(null);
                    HandleAddClient();
                    setAction("Edit");
                  }}
                >
                  <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                  Edit Customer
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpen(null);
                    setAction("View");
                    HandleAddClient();
                    specificBillerData(selectedId);
                  }}
                >
                  <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                  View Customer
                </MenuItem>
                <MenuItem
                  sx={{ color: "error.main" }}
                  onClick={() => {
                    setOpen(null);
                    deleteClient(selectedId);
                  }}
                >
                  <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                  Delete Customer
                </MenuItem>
              </Popover>
            </>
          ) : (
            <AddClient
              HandleAddClient={HandleAddClient}
              action={action}
              AllClientsList={AllClientsList}
              currentPage={currentPage}
              limit={limit}
              setTotalItems={setTotalItems}
            />
          )}
        </>
      )}
    </Paper>
  );
};

export default AllClients;
