import { Navigate, Route, Routes, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import Clients from "./pages/Client";
import AllClients from "./components/AllClients/AllClients";
import Billertable from "./components/Biller/BillerTable";
import Addoutward from "./components/Outward/addoutward";
import Outward from "./components/Outward/Outward";
import AddInward from "./components/Inward/Inward";
import StockReport from "./components/StockReport/stock-report.js";
import PurchaseInventory from "./components/PurchaseInventory/PurchaseInventory";
import Addproduct from "./components/product/product.js";
import BulkProduct from "./components/Bulkproduct/Bulkproduct";
import AddBiller from "./components/Biller/BillerField";
import InvoiceTable from "./components/InvoicesTable/invoiceTable";
import QualityReportTable from "./components/QualityReportTable/qualityReportTable";
import StockReportPdf from "./components/templates/stockReportspdf";
import QualityReportSpl from "./components/templates/qualityReportSpl";
import StockReportTable from "./components/StockReport/stockReportTable";
import VendorsTable from "./components/Vendors/Vendor";
import ClientFields from "./components/AllClients/ClientFields";
import ProcessSheetTable from "./components/ProcessSheet/ProcessDataSheet";

// ----------------------------------------------------------------------

const Router = ({ isAuthenticated, onLogin, onLogout }) => {
  return (
    <Routes>
      <Route path="/addClientForm" element={<ClientFields />} />
      {isAuthenticated === "Admin" ? (
        <>
          <Route path="*" element={<Navigate to="/admin/app" />} />
          <Route
            path="/admin"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="/admin/app" element={<DashboardAppPage />} />
            <Route path="/admin/users" element={<Billertable />} />
            <Route path="/admin/add-user" element={<AddBiller />} />
            <Route path="/admin/allcustomers" element={<AllClients />} />
            <Route path="/admin/client" element={<Clients />} />
            <Route path="/admin/Outward" element={<Outward />} />
            {/* <Route path='/admin/addinward' element={<AddInward />} /> */}
            {/* <Route path="/admin/stockReport" element={<StockReport />} /> */}
            <Route path="/admin/stockReport" element={<StockReportTable />} />
            <Route path="/admin/invoices" element={<InvoiceTable />} />
            <Route path="/admin/vendor" element={<VendorsTable/>} />
            <Route
              path="/admin/quality-report"
              element={<QualityReportTable />}
            />
            <Route
              path="/admin/purchase_inventory"
              element={<PurchaseInventory />}
            />
            {/* <Route path='/admin/bulkproduct' element={<BulkProduct />} /> */}
            {/* <Route path='/admin/invoice' element={<Invoice />} /> */}
            <Route path="/admin/challan" element={<AddInward />} />
            <Route path="/admin/process_sheet" element={<ProcessSheetTable />} />
          </Route>
        </>
      ) : isAuthenticated === "Biller" ? (
        <>
          <Route path="*" element={<Navigate to="/biller/allcustomers" />} />
          <Route
            path="/biller"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            {/* <Route path="/biller/app" element={<DashboardAppPage />} /> */}
            {/* <Route path="/biller/users" element={<Billertable />} /> */}
            <Route path="/biller/allcustomers" element={<AllClients />} />
            <Route path="/biller/client" element={<Clients />} />
            <Route path="/biller/Outward" element={<Outward />} />
            <Route path="/biller/challan" element={<AddInward />} />
            <Route path="/biller/stockreport" element={<StockReportTable />} />
            <Route
              path="/biller/quality-report"
              element={<QualityReportTable />}
            />
            {/* <Route
              path="/biller/purchase_inventory"
              element={<PurchaseInventory />}
            /> */}
            {/* <Route path='/admin/bulkproduct' element={<BulkProduct />} /> */}
            {/* <Route path='/admin/invoice' element={<Invoice />} /> */}
          </Route>
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
          <Route path="404" element={<Page404 />} />
        </>
      )}
    </Routes>
  );
};

export default Router;
