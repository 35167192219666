import { configureStore } from "@reduxjs/toolkit";
import AllBillerListReducer from "../redux/Slices/AllBillerSlices";
import AllClientsListReducer from '../redux/Slices/AllClientSlices';
import AllProductListReducer from '../redux/Slices/AllProductSclices';
import AllInwardListReducer from "../redux/Slices/AllInwardSlices";
import AllOutwardListReducer from '../redux/Slices/AllOutwardSlices';
import AllStockReportListReducer from '../redux/Slices/AllStockReport';
import AllVendorListReducer from '../redux/Slices/AllVendorSlices';
import AllProcessDataList from '../redux/Slices/AllProcessDataSlices';
export const store = configureStore({
    reducer: {
        ALLBILLERLIST: AllBillerListReducer,
        ALLCLIENTSLIST: AllClientsListReducer,
        ALLPRODUCTLIST: AllProductListReducer,
        ALLINWARDLIST: AllInwardListReducer,
        ALLOUTWARDLIST: AllOutwardListReducer,
        AllSTOCKREPORT: AllStockReportListReducer,
        ALLVENDORLIST: AllVendorListReducer,
        ALLPROCESSDATA: AllProcessDataList,
    },
});