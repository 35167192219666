import { createSlice } from "@reduxjs/toolkit";

const ALLPROCESSDATALIST = createSlice({
    name: "ALLPROCESSDATALIST",
    initialState: {
        AllProcessDataList: "",
        specificProcessData: ""
    },
    reducers: {
        setALLPROCESSDATALIST: (state, action) => {
            state.AllProcessDataList = action.payload;
        },
        setSpecificProcessData: (state, action) => {
            state.specificProcessData = action.payload;
        },
    },
});

export const { setALLPROCESSDATALIST, setSpecificProcessData } = ALLPROCESSDATALIST.actions;
export default ALLPROCESSDATALIST.reducer;
