import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Button,
    IconButton,
    Popover,
    MenuItem,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import Iconify from "../iconify";
import AddVendor from "./AddVendor";
import VENDORAPI from "src/API_Routes/VendorAPI/VendorAPI";
import { ToastContainer, toast } from "react-toastify";
import { deleteData, getData } from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import { setALLVENDORLIST, setSpecificVendorDetails } from "src/redux/Slices/AllVendorSlices";
import vendorlogo from "../logo/vendor.png";
import AddVendors from "./AddVendor";
import { showConfirmationDialog } from "src/utils/sweetalertUtils";


const VendorsTable = () => {

    const dispatch = useDispatch();
    const [open, setOpen] = useState(null);
    const [addVendor, setAddvendor] = useState();
    const [action, setAction] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [TotalItems, setTotalItems] = useState();
    const [limit, setLimit] = useState(5);
    const accessToken = sessionStorage.getItem("Token");
    const [selectedId, setSelectedId] = useState();
    const ALLVENDORLIST = useSelector(
        (state) => state?.ALLVENDORLIST?.AllVendorList?.results
    );

    const HandleAddVendor = () => {
        setAddvendor(!addVendor);
    };

    const handleLimitChange = (event) => {
        setCurrentPage(1);
        setLimit(event.target.value);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        // specificBillerData();
        setSelectedId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    // index based on currentPage
    const calculateIndex = (index) => {
        return (currentPage - 1) * limit + index + 1;
    };

    const AllVendorList = async (page) => {
        const params = {
            page: page,
            limit: limit,
        };
        try {
            const res = await getData(VENDORAPI.GETVENDOR, params, accessToken);
            if (res.status === 200) {
                dispatch(setALLVENDORLIST(res.data));
                setTotalPages(res.data.totalPages);
                if (res?.data?.totalItems) {
                    setTotalItems(res.data.totalItems);
                } else {
                    setTotalItems(0);
                }

                console.log(ALLVENDORLIST, "ALLVENDORLIST");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const deleteVendor = async (userId) => {
        const isConfirmed = await showConfirmationDialog();
        if (isConfirmed) {
            await deleteData(`vendor/vendor/${userId}`, accessToken)
                .then((res) => {
                    setOpen(null);
                    AllVendorList(currentPage);
                })
                .catch((error) => {
                    setOpen(null);
                    console.error("Error:", error);
                });
        }
    };

    const specificVendorData = async (userId) => {
        try {
            const res = await getData(`vendor/vendor/${userId}`, null, accessToken);
            if (res.status === 200) {
                dispatch(setSpecificVendorDetails(res.data));
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        AllVendorList(currentPage);
    }, [currentPage, limit, addVendor]);


    return (
        <Paper className="card-body">
            <ToastContainer position="top-right" />

            {!addVendor ? (
                <>
                    <div className="pt-3 pb-4 d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <img src={vendorlogo} width="5%" className="mr-2" />
                            <p className="fw-bold mb-0 color-dark mt-1">Total Users : {TotalItems}</p>
                        </div>
                        <Button
                            variant="contained"
                            size="small"
                            className="ml-8 btn-primary cursor-pointer"
                            onClick={() => {
                                setOpen(null);
                                setAction("Add");
                                HandleAddVendor();
                            }}
                        >
                            + ADD VENDOR
                        </Button>
                    </div>
                    <TableContainer style={{ height: "361px", overflowY: "scroll" }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-cl color-dark text-center">
                                        S.No
                                    </TableCell>
                                    <TableCell className="table-cl color-dark">
                                        Company Name
                                    </TableCell>
                                    <TableCell className="table-cl color-dark">
                                        Vendor Name
                                    </TableCell>
                                    <TableCell className="table-cl color-dark">
                                        Mobile No
                                    </TableCell>
                                    <TableCell className="table-cl color-dark">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ALLVENDORLIST &&
                                    ALLVENDORLIST.map((row, index) => (
                                        <TableRow key={index} className="padding-8">
                                            <TableCell className="text-center">
                                                {calculateIndex(index)}
                                            </TableCell>
                                            <TableCell>{row.companyName}</TableCell>
                                            <TableCell>{row.vendorName}</TableCell>
                                            <TableCell>
                                                {row.phoneNumber || row.mobileNumber}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size="large"
                                                    color="inherit"
                                                    onClick={(event) => handleOpenMenu(event, row._id)}
                                                >
                                                    <Iconify icon={"eva:more-vertical-fill"} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {!ALLVENDORLIST && (
                                    <TableRow>
                                        <TableCell colSpan={5} className="text-center">
                                            No Records Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className="d-flex flex-wrap justify-content-end m-3">
                        <FormControl variant="outlined" size="small" style={{ width: "5%" }}>
                            <Select
                                labelId="limit-label"
                                id="limit-select"
                                value={limit}
                                onChange={handleLimitChange}
                            >
                                <MenuItem value={5}>5</MenuItem>
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={15}>15</MenuItem>
                                <MenuItem value={TotalItems}>All</MenuItem>
                            </Select>
                        </FormControl>
                        <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                            <Iconify icon={"grommet-icons:previous"} />
                        </Button>
                        <Button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <Iconify icon={"grommet-icons:next"} />
                        </Button>
                    </div>
                    <Popover
                        open={Boolean(open)}
                        anchorEl={open}
                        onClose={handleCloseMenu}
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        PaperProps={{
                            sx: {
                                p: 1,
                                width: 160,
                                "& .MuiMenuItem-root": {
                                    px: 1,
                                    typography: "body2",
                                    borderRadius: 0.75,
                                },
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                specificVendorData(selectedId);
                                setOpen(null);
                                HandleAddVendor();
                                setAction("Edit");
                            }}
                        >
                            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                            Edit Vendor
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setOpen(null);
                                setAction("View");
                                HandleAddVendor();
                                specificVendorData(selectedId);
                            }}
                        >
                            <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                            View Vendor
                        </MenuItem>
                        <MenuItem
                            sx={{ color: "error.main" }}
                            onClick={() => {
                                setOpen(null);
                                deleteVendor(selectedId);
                            }}
                        >
                            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                            Delete Vendor
                        </MenuItem>
                    </Popover>
                </>
            ) : (
                <AddVendors
                    HandleAddVendor={HandleAddVendor}
                    action={action}
                // AllBillerList={AllBillerList}
                // setTotalItems={setTotalItems}
                />
            )}
        </Paper>
    )
}

export default VendorsTable;